import { useMutation } from "react-query";
import {
    getUserInfoAPI,
    getUserPointAPI,
    postUserLoginAPI,
    postUserSignupAPI,
} from "../../api/service/UserServiceAPI";
import { useNavigate } from "react-router-dom";

function useUserService() {
    const navigate = useNavigate();

    const { mutate: postUserSignupMutate } = useMutation(postUserSignupAPI);
    const { mutate: postUserLoginMutate } = useMutation(postUserLoginAPI);
    const { mutate: getUserInfoMutate } = useMutation(getUserInfoAPI);
    const { mutate: getUserPointMutate } = useMutation(getUserPointAPI);

    // 회원가입
    const postUserSignup = (data) => {
        postUserSignupMutate(
            { data },
            {
                onSuccess: (data) => {
                    if (data.error) {
                        alert(data.error);
                    } else {
                        alert("환영합니다.");
                        navigate("/");
                    }
                },
                onError: (data) => {
                    console.log(data);
                },
            },
        );
    };

    // 로그인
    const postUserLogin = (data) => {
        postUserLoginMutate(
            { data },
            {
                onSuccess: (headers) => {
                    if (headers.data.error) {
                        alert(headers.data.error);
                    }

                    const token = headers.headers.token;

                    if (token !== undefined) {
                        sessionStorage.setItem("token", token);
                        sessionStorage.setItem(
                            "userId",
                            headers.headers.userid,
                        );
                        navigate("/");
                    }
                },
                onError: (headers) => {
                    console.log(headers);
                },
            },
        );
    };

    // 회원정보
    const getUserInfo = ({ data, setUserInfo }) => {
        getUserInfoMutate(
            { data },
            {
                onSuccess: (data) => {
                    setUserInfo(data.response);
                },
                onError: (data) => {
                    console.log(data);
                },
            },
        );
    };

    // 회원포인트
    const getUserPoint = ({ data, setPoint }) => {
        getUserPointMutate(
            { data },
            {
                onSuccess: (data) => {
                    setPoint(data.response.totalPoint);
                },
                onError: (data) => {
                    console.log(data);
                },
            },
        );
    };

    return { postUserSignup, postUserLogin, getUserInfo, getUserPoint };
}

export default useUserService;
