import styled from "styled-components";

export const HomeHeader = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
`;

export const HomeImageBox = styled.div`
    display: flex;
    width: 300px;
    height: 300px;
`;
